<template>
  <video
    ref="videoRef"
    playsinline
    disablepictureinpicture
    loop
    autoplay
    muted
    :width="width"
    :height="height"
    :poster="poster"
  >
    <slot></slot>
  </video>
</template>

<script lang="ts" setup>
defineProps<{
  width: number
  height: number
  poster: string
}>()
const videoRef = ref()
onMounted(() => {
  if ('IntersectionObserver' in window) {
    const lazyVideoObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (video) {
        if (video.isIntersecting) {
          for (const source in video.target.children) {
            const videoSource = video.target.children[source]
            if (
              typeof videoSource.tagName === 'string' &&
              videoSource.tagName === 'SOURCE'
            ) {
              videoSource.src = videoSource.dataset.src
            }
          }

          video.target.load()
          lazyVideoObserver.unobserve(video.target)
        }
      })
    })

    lazyVideoObserver.observe(videoRef.value)
  }
})
</script>
